import axios from '@/common/axios'
import qs from 'qs'

export function addGlassLedger (data) {
  return axios({
    method: 'post',
    url: '/quality/glassLedger/add',
    data: qs.stringify(data)
  })
}

export function deleteGlassLedger (id) {
  return axios({
    method: 'delete',
    url: '/quality/glassLedger/delete/' + id
  })
}

export function updateGlassLedger (data) {
  return axios({
    method: 'put',
    url: '/quality/glassLedger/update',
    data: qs.stringify(data)
  })
}

export function selectGlassLedgerInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/glassLedger/info/' + id
  })
}

export function selectGlassLedgerList (query) {
  return axios({
    method: 'get',
    url: '/quality/glassLedger/list',
    params: query
  })
}

export function reviewGlassLedger (data) {
  return axios({
    method: 'put',
    url: '/quality/glassLedger/updateReview',
    data: qs.stringify(data)
  })
}
