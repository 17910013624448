<template>
  <div id="glassLedger">
    <el-dialog
      :title="glassLedgerFormTitle"
      width="1200px"
      :visible.sync="glassLedgerDialogVisible"
      :close-on-click-modal="false"
      @close="glassLedgerDialogClose"
    >
      <div id="pdfDom">
        <el-form
          ref="glassLedgerFormRef"
          :model="glassLedgerForm"
          :rules="glassLedgerFormRules"
          label-position="right"
          label-width="100px"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="校验员" prop="checker">
                <el-input
                  v-model="glassLedgerForm.checker"
                  placeholder="请输入校验员"
                  clearable
                  :disabled="
                    glassLedgerFormTitle !== '新增玻璃仪器校验台账' &&
                    glassLedgerFormTitle !== '修改玻璃仪器校验台账' &&
                    glassLedgerFormTitle !== '玻璃仪器校验台账详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="核验员" prop="verifier">
                <el-input
                  v-model="glassLedgerForm.verifier"
                  placeholder="请输入核验员"
                  clearable
                  :disabled="
                    glassLedgerFormTitle !== '核验玻璃仪器校验台账' &&
                    glassLedgerFormTitle !== '玻璃仪器校验台账详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="水温" prop="waterTemperature">
                <el-input
                  v-model="glassLedgerForm.waterTemperature"
                  placeholder="请输入水温"
                  clearable
                  :disabled="
                    glassLedgerFormTitle !== '新增玻璃仪器校验台账' &&
                    glassLedgerFormTitle !== '修改玻璃仪器校验台账' &&
                    glassLedgerFormTitle !== '玻璃仪器校验台账详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="水的质量" prop="waterQuality">
                <el-input
                  v-model="glassLedgerForm.waterQuality"
                  placeholder="请输入水的质量"
                  clearable
                  :disabled="
                    glassLedgerFormTitle !== '新增玻璃仪器校验台账' &&
                    glassLedgerFormTitle !== '修改玻璃仪器校验台账' &&
                    glassLedgerFormTitle !== '玻璃仪器校验台账详情'
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <vxe-toolbar
              v-if="
                glassLedgerFormTitle === '新增玻璃仪器校验台账' ||
                glassLedgerFormTitle === '修改玻璃仪器校验台账'
              "
            >
              <template #buttons>
                <vxe-button @click="insertRowEvent()"> 新增 </vxe-button>
              </template>
            </vxe-toolbar>
            <vxe-table
              ref="xTable"
              border
              resizable
              show-overflow
              keep-source
              :data="ledgerDetailList"
              :edit-config="{ trigger: 'click', mode: 'cell' }"
            >
              <vxe-table-column
                field="deviceNo"
                title="仪器编号"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <vxe-table-column
                field="deviceName"
                title="仪器名称"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <vxe-table-column
                field="readVolume"
                title="读数的容积（ml）"
                width="110"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <vxe-table-column
                field="bottleWaterQuality"
                title="瓶与水的质量（g）"
                width="130"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <vxe-table-column
                field="waterQuality"
                title="水的质量（g）"
                width="100"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <vxe-table-column
                field="actualVolume"
                title="实际容积（ml）"
                width="100"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <vxe-table-column
                field="effectiveValue"
                title="效正值（ml）"
                width="90"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <vxe-table-column
                field="eligibility"
                title="是否合格"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <vxe-table-column
                v-if="
                  glassLedgerFormTitle === '新增玻璃仪器校验台账' ||
                  glassLedgerFormTitle === '修改玻璃仪器校验台账'
                "
                title="操作"
                width="100"
              >
                <template #default="{ row }">
                  <template>
                    <vxe-button @click="removeRowEvent(row)"> 删除 </vxe-button>
                  </template>
                </template>
              </vxe-table-column>
            </vxe-table>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="效验结论" prop="conclusion">
                <el-input
                  v-model="glassLedgerForm.conclusion"
                  placeholder="请输入效验结论"
                  clearable
                  :disabled="
                    glassLedgerFormTitle !== '新增玻璃仪器校验台账' &&
                    glassLedgerFormTitle !== '修改玻璃仪器校验台账' &&
                    glassLedgerFormTitle !== '玻璃仪器校验台账详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="效验日期" prop="effectiveDate">
                <el-date-picker
                  v-model="glassLedgerForm.effectiveDate"
                  placeholder="请选择效验日期"
                  value-format="yyyy-MM-dd"
                  :disabled="
                    glassLedgerFormTitle !== '新增玻璃仪器校验台账' &&
                    glassLedgerFormTitle !== '修改玻璃仪器校验台账' &&
                    glassLedgerFormTitle !== '玻璃仪器校验台账详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="下次效验日期" prop="nextValidationDate">
                <el-date-picker
                  v-model="glassLedgerForm.nextValidationDate"
                  placeholder="请选择下次效验日期"
                  value-format="yyyy-MM-dd"
                  :disabled="
                    glassLedgerFormTitle !== '新增玻璃仪器校验台账' &&
                    glassLedgerFormTitle !== '修改玻璃仪器校验台账' &&
                    glassLedgerFormTitle !== '玻璃仪器校验台账详情'
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="glassLedgerDialogVisible = false"> 取 消 </el-button>
        <el-button type="primary" @click="glassLedgerFormSubmit">
          确 定
        </el-button>
        <el-button v-if="glassLedgerFormTitle === '玻璃仪器校验台账详情'" type="primary" @click="getPdf">
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="校验员">
        <el-input
          v-model="searchForm.checker"
          placeholder="请输入校验员"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['GLASS_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="glassLedgerPage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="checker" label="校验员" />
      <el-table-column prop="verifier" label="核验员" />
      <el-table-column prop="waterTemperature" label="水温" />
      <el-table-column prop="waterQuality" label="水的质量" />
      <el-table-column prop="conclusion" label="效验结论" />
      <el-table-column label="效验日期">
        <template slot-scope="scope">
          <span v-if="scope.row.effectiveDate">{{
            scope.row.effectiveDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="下次效验日期">
        <template slot-scope="scope">
          <span v-if="scope.row.nextValidationDate">{{
            scope.row.nextValidationDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="200" fixed="right">
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['GLASS_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['GLASS_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['GLASS_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            核验
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="glassLedgerPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addGlassLedger,
  deleteGlassLedger,
  updateGlassLedger,
  selectGlassLedgerInfo,
  selectGlassLedgerList,
  reviewGlassLedger,
} from "@/api/quality/glassLedger";
import html2PDF from 'jspdf-html2canvas'
import { Loading } from 'element-ui'

export default {
  data() {
    return {
      glassLedgerDialogVisible: false,
      glassLedgerFormTitle: "",
      glassLedgerForm: {
        id: "",
        checker: "",
        verifier: "",
        waterTemperature: "",
        waterQuality: "",
        conclusion: "",
        effectiveDate: "",
        nextValidationDate: "",
        status: "",
        glassLedgerDetailJson: "",
        taskId: "",
      },
      glassLedgerFormRules: {
        checker: [
          {
            required: true,
            message: "校验员不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      glassLedgerPage: {
        list: [],
        total: 0,
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        checker: "",
      },
      ledgerDetailList: [],
    };
  },
  created() {
    selectGlassLedgerList(this.searchForm).then((res) => {
      this.glassLedgerPage = res;
    });
  },
  methods: {
    glassLedgerDialogClose() {
      this.$refs.glassLedgerFormRef.resetFields();
      this.ledgerDetailList = [];
    },
    glassLedgerFormSubmit() {
      if (this.glassLedgerFormTitle === "玻璃仪器校验台账详情") {
        this.glassLedgerDialogVisible = false;
        return;
      }
      this.$refs.glassLedgerFormRef.validate(async (valid) => {
        if (valid) {
          if (this.glassLedgerFormTitle === "新增玻璃仪器校验台账") {
            this.glassLedgerForm.id = "";
            this.glassLedgerForm.status = 1;
            this.glassLedgerForm.glassLedgerDetailJson = JSON.stringify(
              this.$refs.xTable.getTableData().tableData
            );
            await addGlassLedger(this.glassLedgerForm);
          } else if (this.glassLedgerFormTitle === "修改玻璃仪器校验台账") {
            this.glassLedgerForm.glassLedgerDetailJson = JSON.stringify(
              this.$refs.xTable.getTableData().tableData
            );
            await updateGlassLedger(this.glassLedgerForm);
          } else if (this.glassLedgerFormTitle === "核验玻璃仪器校验台账") {
            this.glassLedgerForm.status = 2;
            await reviewGlassLedger(this.glassLedgerForm);
          }
          this.glassLedgerPage = await selectGlassLedgerList(this.searchForm);
          this.glassLedgerDialogVisible = false;
        }
      });
    },
    handleAdd() {
      this.glassLedgerFormTitle = "新增玻璃仪器校验台账";
      this.glassLedgerDialogVisible = true;
    },
    handleDelete(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        await deleteGlassLedger(row.id);
        if (
          this.glassLedgerPage.list.length === 1 &&
          this.searchForm.pageNum > 1
        ) {
          this.searchForm.pageNum--;
        }
        this.glassLedgerPage = await selectGlassLedgerList(this.searchForm);
      });
    },
    handleUpdate(index, row) {
      this.glassLedgerFormTitle = "修改玻璃仪器校验台账";
      this.glassLedgerDialogVisible = true;
      this.selectGlassLedgerInfoById(row.id);
    },
    handleReview(index, row) {
      this.glassLedgerFormTitle = "核验玻璃仪器校验台账";
      this.glassLedgerDialogVisible = true;
      this.selectGlassLedgerInfoById(row.id);
    },
    handleInfo(index, row) {
      this.glassLedgerFormTitle = "玻璃仪器校验台账详情";
      this.glassLedgerDialogVisible = true;
      this.selectGlassLedgerInfoById(row.id);
    },
    selectGlassLedgerInfoById(id) {
      selectGlassLedgerInfo(id).then((res) => {
        this.glassLedgerForm.id = res.id;
        this.glassLedgerForm.checker = res.checker;
        this.glassLedgerForm.verifier = res.verifier;
        this.glassLedgerForm.waterTemperature = res.waterTemperature;
        this.glassLedgerForm.waterQuality = res.waterQuality;
        this.glassLedgerForm.conclusion = res.conclusion;
        this.glassLedgerForm.effectiveDate = res.effectiveDate;
        this.glassLedgerForm.nextValidationDate = res.nextValidationDate;
        this.glassLedgerForm.status = res.status;
        this.glassLedgerForm.taskId = res.taskId;
        this.ledgerDetailList = res.ledgerDetailList;
      });
    },
    handleSearch() {
      this.searchForm.pageNum = 1;
      selectGlassLedgerList(this.searchForm).then((res) => {
        this.glassLedgerPage = res;
      });
    },
    pageNumChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      selectGlassLedgerList(this.searchForm).then((res) => {
        this.glassLedgerPage = res;
      });
    },
    pageSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      selectGlassLedgerList(this.searchForm).then((res) => {
        this.glassLedgerPage = res;
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (this.checkPermission(["GLASS_REVIEW"]) && row.status === 1) {
        return "review";
      }
      return "";
    },
    async insertRowEvent(row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1);
      await this.$refs.xTable.setActiveRow(newRow);
    },
    removeRowEvent(row) {
      this.$refs.xTable.remove(row);
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `玻璃仪器校验台账${this.glassLedgerForm.effectiveDate.substring(0, 10)}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
};
</script>

<style>
</style>
